/* eslint-disable no-undef */
import '../styles/globals.scss';
import { shape, func } from 'prop-types';
import Head from 'next/head';
import Script from 'next/script';
import FirebaseConfigScript from 'components/scripts/FirebaseConfigScript';
import GoogleAnalyticsScript from 'components/scripts/GoogleAnalyticsScript';
import SegmentConfigScript from 'components/scripts/SegmentConfigScript';
import AmplitudeConfigScript from 'components/scripts/AmplitudeConfigScript';

function MyApp({ Component, pageProps }) {
  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta content="IE=edge,chrome=1" httpEquiv="X-UA-Compatible" />
        <title>Meal-Kit Plans & Pricing | Food Delivery Service | Blue Apron</title>
        <meta
          name="description"
          content="Blue Apron cost per meal varies based on the serving size and meal kit you choose. Learn more about how to make the most of your Blue Apron subscription."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        {DYNAMIC_YIELD_ENABLED && (
          <>
            <link rel="preconnect" href="//cdn.dynamicyield.com" />
            <link rel="preconnect" href="//st.dynamicyield.com" />
            <link rel="preconnect" href="//rcom.dynamicyield.com" />
            <link rel="dns-prefetch" href="//cdn.dynamicyield.com" />
            <link rel="dns-prefetch" href="//st.dynamicyield.com" />
            <link rel="dns-prefetch" href="//rcom.dynamicyield.com" />
          </>
        )}
      </Head>
      <FirebaseConfigScript />
      <GoogleAnalyticsScript />
      <SegmentConfigScript />
      <AmplitudeConfigScript />
      <Script id="google-conversion-variables" strategy="afterInteractive">
        {`/* <![CDATA[ */
          var google_conversion_id = '${GOOGLE_CONV_ID}';
          var google_conversion_label = '${GOOGLE_CONV_LABEL}';
          var google_custom_params = window.google_tag_params;
          var google_remarketing_only = true;
          /* ]]> */`}
      </Script>
      <Script src="//www.googleadservices.com/pagead/conversion.js" strategy="afterInteractive" />
      <Component {...pageProps} />
    </>
  );
}

MyApp.propTypes = {
  Component: func,
  pageProps: shape({})
};

MyApp.defaultProps = {
  Component: () => {},
  pageProps: {}
};

export default MyApp;
