import React from 'react';
import Script from 'next/script';

const SegmentConfigScript = () => {
  return (
    <Script id="segment-config-script" strategy="afterInteractive">
      {`
        function readCookie(name) {
          var nameEQ = name + '=';
          var ca = document.cookie.split(';');
          var c;
        
          for (var i = 0; i < ca.length; i += 1) {
            c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
          }
          return null;
        }

        window.SEGMENT_PUBLIC_KEY = '${SEGMENT_PUBLIC_KEY}';
        window.SEGMENT_VIOLATION_TOAST_ENABLED = ${SEGMENT_VIOLATION_TOAST_ENABLED};

        (function(){
          // Return if no segment key is set
          if (!'${SEGMENT_PUBLIC_KEY}') return;
          // Create a queue, but don't obliterate an existing one!
          var analytics = window.analytics = window.analytics || [];
          // If the real analytics.js is already on the page, return.
          if (analytics.initialize) return;
          // If the snippet was invoked already show an error.
          if (analytics.invoked) {
            if (window.console && console.error) { console.error('Segment snippet included twice.'); }
            return;
          }
          // Invoked flag, to make sure the snippet is never invoked twice.
          analytics.invoked = true;
          // A list of the methods in Analytics.js to stub.
          analytics.methods = [
            'trackSubmit', 'trackClick', 'trackLink', 'trackForm', 'pageview', 'identify', 'reset', 'group', 'track', 'ready', 'alias',
            'debug', 'page', 'once', 'off', 'on', 'addSourceMiddleware', 'addIntegrationMiddleware', 'setAnonymousId', 'addDestinationMiddleware'
          ];
          // Define a factory to create stubs. These are placeholders for methods in Analytics.js so that you never have to wait
          // for it to load to actually record data. The \`method\` is stored as the first argument, so we can replay the data.
          analytics.factory = function(method){
            return function(){
              var args = Array.prototype.slice.call(arguments);
              args.unshift(method);
              analytics.push(args);
              return analytics;
            };
          };
          // For each of our methods, generate a queueing stub.
          for (var i = 0; i < analytics.methods.length; i++) {
            var key = analytics.methods[i];
            analytics[key] = analytics.factory(key);
          }
          // Define a method to load Analytics.js from our CDN, and that will be sure to only ever load it once.
          analytics.load = function(key, options){
            // Create an async script element based on your key.
            var script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            script.src = 'https://cdn.segment.com/analytics.js/v1/' + key + '/analytics.min.js';
            var first = document.getElementsByTagName('script')[0];
            first.parentNode.insertBefore(script, first);
            analytics._loadOptions = options;
          };
          analytics._writeKey = "${SEGMENT_PUBLIC_KEY}"
          // Add a version to keep track of what's in the wild.
          analytics.SNIPPET_VERSION = '4.15.2';
          // Configuration for loading specific information

          /* Returns the cookie and value for each consent group: C0001, C0002, C0003, C0004, C0005
            C0002: perfomance/analytics, C0003: functional, C0004: targeted, C0005: social_media/advertising
            with their respective values, '1' for true, '0' for false
            C0001 will always be '1' since is necessary and can't be disabled.
            e.g: {C0001: '1', C0002: '1', C0003: '0', C0004: '1', C0005: '1'}
          */
          function readConsentCookie(){
            const optanonCookie = readCookie('OptanonConsent')
            if (optanonCookie){
              var consentGroups = optanonCookie.split('&').find( x => x.startsWith('groups=')).replace('groups=','').split('%2C')
              return consentGroups.reduce((prev, curr) => {
                var cookie = curr.split('%3A'); return {...prev, ...{[cookie[0]]: cookie[1]}}
              }, new Map())
            }
          }

          function isOneTrustEnabled(category) {
            const COOKIE_GROUP_VALUE  = readConsentCookie();
        
            switch(category) {
              case 'C0001':
              case 'C0003':
                // Necessary and Functional are now Always Active
                return true;
              case 'C0002':
              case 'C0004':
                // Both Targeting and Perfomance cookies need to be Active in order to enable consent
                return !!COOKIE_GROUP_VALUE && (COOKIE_GROUP_VALUE['C0002'] == '1' && COOKIE_GROUP_VALUE['C0004'] == '1')
              default:
                // If the destination doesn't have a specific category, then consent is disabled.
                return false;
            }
          }
        
          function buildDestinationsWithConsent() {
            // Manually add any new destination with the name that appears on Segment Destination
            // C0002: perfomance/analytics, C0003: functional, C0004: targeted/advertising
            const DESTINATION_ONETRUST_MAPPING = {
              'Bing Ads': 'C0004',
              'Branch Metrics': 'C0002',
              'Braze Cloud Mode (Actions)': 'C0002',
              'DoubleClick Floodlight': 'C0004',
              'Facebook Conversions API (Actions)': 'C0004',
              'Google Ads (Classic)': 'C0004',
              'Google Analytics 4 Cloud': 'C0002',
              'Pinterest Conversions API': 'C0004',
              'TikTok Audiences': 'C0004',
              'TikTok Conversions': 'C0004',
            }
        
            const destinations = {
              'All': false, // do not send downstream when no destination key is found
              'BigQuery Production': true,
              'Google Tag Manager': true, // Consent will be handled within GTM
              'Segment.io': true,
            }
        
            for (const dest in DESTINATION_ONETRUST_MAPPING) {
              destinations[dest] = isOneTrustEnabled(DESTINATION_ONETRUST_MAPPING[dest]);
            }
        
            return destinations;
          }

          function dynamicYeildSetActiveConsent() {
              if (window.DY) {
                const isEnabled = isOneTrustEnabled('C0002')
                window.DY.userActiveConsent = { accepted: isEnabled }
              }
            }

          window.analytics.load(SEGMENT_PUBLIC_KEY, { integrations:  buildDestinationsWithConsent() });
        
          analytics.page();
          })();
      `}
    </Script>
  );
};

export default SegmentConfigScript;
